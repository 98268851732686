//character images

import sonny_01 from "../../assets/images/Sonny/Sonny_1.png";
import sonny_02 from "../../assets/images/Sonny/Sonny_2.png";
import sonny_03 from "../../assets/images/Sonny/Sonny_3.png";
import sonny_11 from "../../assets/images/Sonny/Sonny_11.png";
import sonny_12 from "../../assets/images/Sonny/Sonny_12.png";
import sonny_13 from "../../assets/images/Sonny/Sonny_13.png";

import asiqur_01 from "../../assets/images/Asiqur/Asiqur_1.png";
import asiqur_02 from "../../assets/images/Asiqur/Asiqur_2.png";
import asiqur_03 from "../../assets/images/Asiqur/Asiqur_3.png";
import asiqur_11 from "../../assets/images/Asiqur/Asiqur_11.png";
import asiqur_12 from "../../assets/images/Asiqur/Asiqur_12.png";
import asiqur_13 from "../../assets/images/Asiqur/Asiqur_13.png";


import lillian_01 from "../../assets/images/Lillian/Lillian_1.png";
import lillian_02 from "../../assets/images/Lillian/Lillian_2.png";
import lillian_03 from "../../assets/images/Lillian/Lillian_3.png";
import lillian_11 from "../../assets/images/Lillian/Lillian_11.png";
import lillian_12 from "../../assets/images/Lillian/Lillian_12.png";
import lillian_13 from "../../assets/images/Lillian/Lillian_13.png";

import brandon_01 from "../../assets/images/Brandon/Brandon_1.png";
import brandon_02 from "../../assets/images/Brandon/Brandon_2.png";
import brandon_03 from "../../assets/images/Brandon/Brandon_3.png";
import brandon_11 from "../../assets/images/Brandon/Brandon_11.png";
import brandon_12 from "../../assets/images/Brandon/Brandon_12.png";
import brandon_13 from "../../assets/images/Brandon/Brandon_13.png";

import dharma_01 from "../../assets/images/Dharma/Dharma_1.png";
import dharma_02 from "../../assets/images/Dharma/Dharma_2.png";
import dharma_03 from "../../assets/images/Dharma/Dharma_3.png";
import dharma_11 from "../../assets/images/Dharma/Dharma_11.png";
import dharma_12 from "../../assets/images/Dharma/Dharma_12.png";
import dharma_13 from "../../assets/images/Dharma/Dharma_13.png";

import malcolm_01 from "../../assets/images/Malcolm/Malcolm_1.png";
import malcolm_02 from "../../assets/images/Malcolm/Malcolm_2.png";
import malcolm_03 from "../../assets/images/Malcolm/Malcolm_3.png";
import malcolm_11 from "../../assets/images/Malcolm/Malcolm_11.png";
import malcolm_12 from "../../assets/images/Malcolm/Malcolm_12.png";
import malcolm_13 from "../../assets/images/Malcolm/Malcolm_13.png";



const heroes = {
  space: {
    speed: 30,
    delimiter: 10, // speed / step
    frames: [
      ["", "", ""], //backward
      ["", "", ""], //forward
    ],
  },
  brandon: {
    speed: 30,
    delimiter: 10, // speed / step
    frames: [
      [brandon_01, brandon_02, brandon_03], //backward
      [brandon_11, brandon_12, brandon_13], //forward
    ],
  },
  malcolm: {
    speed: 30,
    delimiter: 10, // speed / step
    frames: [
      [malcolm_01, malcolm_02, malcolm_03], //backward
      [malcolm_11, malcolm_12, malcolm_13], //forward
    ],
  },
  sonny: {
    speed: 30,
    delimiter: 10, // speed / step
    frames: [
      [sonny_01, sonny_02, sonny_03], //backward
      [sonny_11, sonny_12, sonny_13], //forward
    ],
  },
  lillian: {
    speed: 30,
    delimiter: 10, // speed / step
    frames: [
      [lillian_01, lillian_02, lillian_02], //backward
      [lillian_11, lillian_12, lillian_12], //forward
    ],
  },
  asiqur: {
    speed: 30,
    delimiter: 10, // speed / step
    frames: [
      [asiqur_01, asiqur_02, asiqur_03], //backward
      [asiqur_11, asiqur_12, asiqur_13], //forward
    ],
  },
  dharma: {
    speed: 30,
    delimiter: 10, // speed / step
    frames: [
      [dharma_01, dharma_02, dharma_03], //backward
      [dharma_11, dharma_12, dharma_13], //forward
    ],
  },
};

let count = 0;
let lastDirection = 1;
let currentHero = "sonny";

const heroesShowUp = {
  "0-7050": { hero: "sonny" },
  "7050-12100": { hero: "asiqur" },
  "12100-12600": { hero: "space" },
  "12600-17650": { hero: "dharma" },
  "17650-19050": { hero: "brandon" },
  "19050-21975": { hero: "malcolm" },
  "21975-23100": { hero: "space" },
  "23100-28150": { hero: "lillian" },
  "28150-29050": { hero: "space" },
};

/** search in `animations` by frame(scroll) */
const determineAnim = (scroll) => {
  try {
    let anim = null;

    const currFrame = scroll;

    let animRange;
    Object.keys(heroesShowUp).some((k) => {
      animRange = k.split("-");

      //if it out of current range
      if (currFrame < animRange[0] || animRange[1] <= currFrame) {
        return false;
      }

      anim = heroesShowUp[k];
      return true;
    });

    if (anim === undefined || anim === null || anim === "") return currentHero;

    return anim.hero;
  } catch (e) {
    return currentHero;
  }
};

/**
 *
 * @param {*} onScrolling
 * @param {*} scrollDirection
 */
const getWalkAnimation = (onScrolling, scrollDirection, scroll) => {
  if (onScrolling) {
    currentHero = determineAnim(scroll);
    if (scrollDirection === lastDirection) {
      count = count === heroes[currentHero].speed - 1 ? 0 : count + 1;
    } else {
      count = 0;
    }
    lastDirection = scrollDirection;
  }

  return heroes[currentHero].frames[scrollDirection][
    Math.floor(count / heroes[currentHero].delimiter)
  ];
};

export default getWalkAnimation;
